import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import Login from "./components/Login"
import ChatInterface from "./components/ChatInterface"
import { UserProvider, useUser } from "./contexts/UserContext"
import "./App.css"

const ProtectedRoute = ({ children }) => {
  const { userID } = useUser()

  if (!userID) {
    // If there is no userID, redirect to login
    return <Navigate to="/login" replace />
  }

  return children
}

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <ChatInterface />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/login" replace />} />{" "}
          {/* Redirect to login by default */}
        </Routes>
      </Router>
    </UserProvider>
  )
}

export default App
