import React, { createContext, useContext, useState, useEffect } from "react"

const UserContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [userID, setUserID] = useState(null)

  useEffect(() => {
    const storedUserID = localStorage.getItem("userID") // Get userID from localStorage
    if (storedUserID) {
      setUserID(storedUserID)
    }
  }, [])

  const login = (id) => {
    localStorage.setItem("userID", id) // Set userID in localStorage
    setUserID(id)
  }

  const logout = () => {
    localStorage.removeItem("userID") // Remove userID from localStorage
    setUserID(null)
  }

  return (
    <UserContext.Provider value={{ userID, login, logout }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
