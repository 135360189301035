import React, { useState } from "react"
import moment from "moment"
import "../styles/Message.css"
import BreakdownTable from "./BreakdownTable"

const TriangleIcon = ({ isExpanded }) => {
  const style = {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: isExpanded ? "10px solid black" : "none",
    borderTop: isExpanded ? "none" : "10px solid black",
    cursor: "pointer",
  }

  return <div style={style} />
}

const Message = ({
  text,
  sender,
  type,
  image,
  response,
  userImage,
  newUserImage,
  timestamp,
  mealData = "",
  mealName,
  syft_thread_id,
  syft_thread_rating,
  onDelete,
  onRefresh,
  isFirst,
}) => {
  const [showBreakdown, setShowBreakdown] = useState(true)

  const toggleBreakdown = () => {
    setShowBreakdown(!showBreakdown)
  }
  //   console.log("Meal Data", mealData)
  let nutritionDataNested = ""
  if (mealData.trim() !== "") {
    try {
      mealData = JSON.parse(mealData)
      console.log("Meal Data", JSON.parse(mealData))
    } catch (error) {
      console.log("No Meal Data", text)
      //   console.error("Error parsing JSON:", error)
    }
  }

  function hasNestedItems(obj) {
    let hasNested = false

    function traverse(item, depth) {
      if (item && typeof item === "object" && !Array.isArray(item)) {
        // Increase the depth as we go deeper into nested objects
        const currentDepth = depth + 1
        Object.values(item).forEach((value) => {
          if (typeof value === "object" && !Array.isArray(value)) {
            if (currentDepth > 2) {
              hasNested = true // If depth is greater than 1, we found a nested item
            } else {
              traverse(value, currentDepth) // Continue traversing
            }
          }
        })
      }
    }

    traverse(obj, 0) // Start at depth 0

    return hasNested
  }

  const hasNested = hasNestedItems(mealData.nutritionDataNested)

  let parsedText = typeof text === "string" ? tryParseJSON(text) : text

  function tryParseJSON(jsonString) {
    try {
      var o = JSON.parse(jsonString)
      if (o && typeof o === "object") {
        return o
      }
    } catch (e) {}
    return jsonString // Return as is if parsing fails
  }

  function renderContent(mealData, text) {
    if (
      typeof mealData === "object" &&
      !Array.isArray(mealData) &&
      mealData !== null
    ) {
      //   console.log("nutritionDataNested", nutritionDataNested)
      return (
        <div style={{ paddingTop: 0, marginTop: 0 }}>
          {hasNested ? (
            <button
              onClick={toggleBreakdown}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                right: 23,
                top: 15,
                position: "absolute",
              }}
            >
              <TriangleIcon isExpanded={showBreakdown} />
            </button>
          ) : null}
          <BreakdownTable mealData={mealData} showBreakdown={showBreakdown} />
          {/* {Object.entries(mealData).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {renderContent(value)}
            </div>
          ))} */}
        </div>
      )
    } else {
      return <span>{text}</span>
    }
  }

  const formatDate = (date) => moment(date).format("YYYY-MM-DD HH:mm")

  return (
    <div className={`message ${type} ${isFirst ? "first-message" : ""}`}>
      {image && <img src={image} alt="Avatar" className="avatar" />}
      <div className="message-content">
        {type === "bottyping" && (
          <div>
            {/* <p
              className="response-text"
              style={{ fontSize: "15px", paddingBottom: "5px" }}
            >
              {response}
            </p> */}
            <video
              src={"../assets/Typing.mp4"}
              rate="1.0"
              volume="1.0"
              muted={true}
              style={{ width: "40px", height: "auto" }} // You might want to move this to CSS
              playsInline
              autoPlay
              loop
            >
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {renderContent(mealData, text)}
        {userImage && (
          <img
            src={"https://images.syfthealth.app/userimages/" + userImage}
            alt="User Image"
            className="user-image"
          />
        )}
        {newUserImage && (
          <img
            src={newUserImage}
            alt="New User Image"
            className="new-user-image"
          />
        )}
        {type !== "bottyping" && (
          <p className="timestamp">{formatDate(timestamp)}</p>
        )}
      </div>
    </div>
  )
}

export default Message
