import React from "react"
import "../styles/BreakdownTable.css" // CSS file for styles

const BreakdownTable = ({ mealData, showBreakdown }) => {
  const calculateTotals = (nutritionData) => {
    const totals = {
      Weight: 0,
      Kcals: 0,
      Fibre: 0,
      Carbs: 0,
      Sugars: 0,
      Protein: 0,
      Fat: 0,
      Salt: 0,
    }

    Object.entries(nutritionData).forEach(([itemName, itemData]) => {
      if (itemData.nutrition && itemData.quantity) {
        const quantityFactor = itemData.quantity / 100
        totals.Weight += itemData.quantity
        totals.Kcals += itemData.nutrition.Kcals * quantityFactor
        totals.Fibre += itemData.nutrition.Fibre * quantityFactor
        totals.Carbs += itemData.nutrition.Carbs * quantityFactor
        totals.Sugars += itemData.nutrition.Sugars * quantityFactor
        totals.Protein += itemData.nutrition.Protein * quantityFactor
        totals.Fat += itemData.nutrition.Fat * quantityFactor
        totals.Salt += itemData.nutrition.Salt * quantityFactor
      }
    })
    // console.log("Totals", totals)
    return totals
  }

  let overallTotals = calculateTotals(mealData.nutritionData)

  const renderRow = (itemName, itemData = {}, index, showBreakdown) => {
    // return null
    if (!itemData || typeof itemData !== "object") {
      return null
    }

    if (itemData.nutrition) {
      return (
        <div className="row header-row" key={`row-${index}`}>
          <span className="cell item-cell">{itemName}</span>
          <span className="cell quantity-cell">
            {itemData.quantity.toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {((itemData.nutrition.Carbs * itemData.quantity) / 100).toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {((itemData.nutrition.Protein * itemData.quantity) / 100).toFixed(
              0
            )}
            g
          </span>
          <span className="cell quantity-cell">
            {((itemData.nutrition.Fibre * itemData.quantity) / 100).toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {((itemData.nutrition.Kcals * itemData.quantity) / 100).toFixed(0)}
          </span>
        </div>
      )
    }

    // const overallTotals = calculateTotals(mealData.nutritionData)
    const totals = calculateTotals(mealData.nutritionData)
    const itemTotal = calculateTotals(itemData)
    if (showBreakdown) {
      return (
        <div className="row-group" key={`group-${index}`}>
          <div className="row header-row">
            <span className="cell item-cell">{itemName}</span>
            <span className="cell quantity-cell">
              {itemTotal.Weight.toFixed(0)}g
            </span>
            <span className="cell quantity-cell">
              {itemTotal.Carbs.toFixed(0)}g
            </span>
            <span className="cell quantity-cell">
              {itemTotal.Protein.toFixed(0)}g
            </span>
            <span className="cell quantity-cell">
              {itemTotal.Fibre.toFixed(0)}g
            </span>
            <span className="cell quantity-cell">
              {itemTotal.Kcals.toFixed(0)}
            </span>
          </div>
          <div className="row row-group" key={`total-${index}`}>
            {Object.entries(itemData).map(
              ([nestedItemName, nestedItemData], i) => (
                <div className="row nested-row" key={`nested-${index}-${i}`}>
                  <span className="cell nested-item-cell">
                    {nestedItemName}
                  </span>
                  <span className="cell quantity-cell">
                    {nestedItemData.quantity.toFixed(0)}g
                  </span>
                  <span className="cell quantity-cell">
                    {(
                      (nestedItemData.nutrition.Carbs *
                        nestedItemData.quantity) /
                      100
                    ).toFixed(0)}
                    g
                  </span>
                  <span className="cell quantity-cell">
                    {(
                      (nestedItemData.nutrition.Protein *
                        nestedItemData.quantity) /
                      100
                    ).toFixed(0)}
                    g
                  </span>
                  <span className="cell quantity-cell">
                    {(
                      (nestedItemData.nutrition.Fibre *
                        nestedItemData.quantity) /
                      100
                    ).toFixed(0)}
                    g
                  </span>
                  <span className="cell quantity-cell">
                    {(
                      (nestedItemData.nutrition.Kcals *
                        nestedItemData.quantity) /
                      100
                    ).toFixed(0)}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="row" key={`total-${index}`}>
        <span className="cell item-cell">{itemName}</span>
        <span className="cell quantity-cell">
          {itemTotal.Weight.toFixed(0)}g
        </span>
        <span className="cell quantity-cell">
          {itemTotal.Carbs.toFixed(0)}g
        </span>
        <span className="cell quantity-cell">
          {itemTotal.Protein.toFixed(0)}g
        </span>
        <span className="cell quantity-cell">
          {itemTotal.Fibre.toFixed(0)}g
        </span>
        <span className="cell quantity-cell">{itemTotal.Kcals.toFixed(0)}</span>
      </div>
    )
  }

  return (
    <div className="nutrition-data-table">
      <h2>{mealData.mealName}</h2>
      <div className="nutrition-table">
        <div className="nutrition-row">
          <div className="nutrition-cell">
            <div className="marker-with-title">
              <div className="marker kcals"></div>
              <span className="title">Kcals</span>
            </div>
            <span className="value">
              {overallTotals.Kcals ? overallTotals.Kcals.toFixed(0) : "-"}
            </span>
          </div>
          <div className="nutrition-cell">
            <div className="marker-with-title">
              <div className="marker carbs"></div>
              <span className="title">Carbs</span>
            </div>
            <span className="value">
              {overallTotals.Carbs ? overallTotals.Carbs.toFixed(0) + "g" : "-"}
            </span>
          </div>
          <div className="nutrition-cell">
            <div className="marker-with-title">
              <div className="marker protein"></div>
              <span className="title">Protein</span>
            </div>
            <span className="value">
              {overallTotals.Protein
                ? overallTotals.Protein.toFixed(0) + "g"
                : "-"}
            </span>
          </div>
        </div>
        <div className="nutrition-row">
          <div className="nutrition-cell">
            <div className="marker-with-title">
              <div className="marker fibre"></div>
              <span className="title">Fibre</span>
            </div>
            <span className="value">
              {overallTotals.Fibre ? overallTotals.Fibre.toFixed(0) + "g" : "-"}
            </span>
          </div>
          <div className="nutrition-cell">
            <div className="marker-with-title">
              <div className="marker fat"></div>
              <span className="title">Fat</span>
            </div>
            <span className="value">
              {overallTotals.Fat ? overallTotals.Fat.toFixed(0) + "g" : "-"}
            </span>
          </div>
          <div className="nutrition-cell">
            <div className="marker-with-title">
              <div className="marker salt"></div>
              <span className="title">Salt</span>
            </div>
            <span className="value">
              {overallTotals.Salt ? overallTotals.Salt.toFixed(0) + "g" : "-"}
            </span>
          </div>
        </div>
      </div>

      <div className="table">
        <div className="row header-row base-line">
          <span className="cell item-cell">Item</span>
          <span className="cell quantity-cell">Wgt</span>
          <span className="cell quantity-cell">Carbs</span>
          <span className="cell quantity-cell">Protein</span>
          <span className="cell quantity-cell">Fibre</span>
          <span className="cell quantity-cell">Kcals</span>
        </div>
        {Object.entries(mealData.nutritionDataNested).map(
          ([itemName, itemData], index) =>
            renderRow(itemName, itemData, index, showBreakdown)
        )}
        <div className="row header-row top-line">
          <span className="cell item-cell">Total</span>
          <span className="cell quantity-cell">
            {overallTotals.Weight.toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {overallTotals.Carbs.toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {overallTotals.Protein.toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {overallTotals.Fibre.toFixed(0)}g
          </span>
          <span className="cell quantity-cell">
            {overallTotals.Kcals.toFixed(0)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default BreakdownTable
